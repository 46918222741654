// 工单管理
import request from '@http';

// 2022/04/24 王江毅 工单列表
export function getWordList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/list',
        params
    })
}

// 2022/06/10 王江毅 工单导出
export function wordExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/export',
        params,
        responseType: 'blob',
    })
}

// 2022/04/25 王江毅 汇款
export function wordAudit(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/audit',
        data
    })
}

// 2022/04/25 王江毅 汇款
export function getToContratAmount(params) {
    return request({
        method: 'get',
        url: `/api/gzf/word/getToContratAmount`,
        params
    })
}

// 2022/06/30 王江毅 汇款
export function getWordInfo(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/word/info/${uuid}`,
    });
}

// 2022/06/30 王江毅 工单处理
export function wordDispose(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/checkout/dispose',
        data
    })
}

// 2022/06/30 王江毅 获取换房数据
export function getRenewalRoomChangeInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/info`,
        params
    })
}

// 2022/06/30 王江毅 换房确认
export function renewalRoomChangeIsCanAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/isCanAudit`,
        params
    });
}

// 2022/06/30 王江毅 指定换房确认人
export function renewalRoomChangeLeasingAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/leasingAudit`,
        params
    });
}

// 2022/06/30 王江毅 换房新签
export function renewalRoomChangeNewContractSign(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/newContract/sign`,
        params
    });
}
// 审批工单列表
export function approvalProcessingRecordGET(params) {
    return request({
        method: 'get',
        url: `/api/gzf/approvalWorkOrder/listPage`,
        params
    });
}
// 审批工单单子详情---优化全部用一个
export function approvalWorkOrderGET(url,params) {
    return request({
        method: 'get',
        url: `/api/gzf/approvalWorkOrder/info/${url}/${params.id}`,

    });
}
// 2024-8-30-审批工单发起
export function approvalWorkOrderPUT(data) {
    return request({
        method: 'put',
        url: `/api/gzf/approvalWorkOrder/launch/contractApproval?id=${data.id}`,
        data
    });
}
// 2024-8-30-审批工单的审批提交--所有的优化到同一个接口-因为调用频繁
export function auditPUT(url,data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/approvalWorkOrder/audit/${url}`,
        data
    });
}
// 2024-8-30-房源管理页面-租金审批页面详情数据获取
export function getRentSubmit(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/approvalWorkOrder/info/rentSubmit/${params.apartmentUuid}`,
        params
    })
}
// 2024-8-30-房源管理页面租金审批页面详情数据提交
export function getRentSubmitPUT(data) {
    return request({
        method: 'post',
        // url: `/api/gzf/approvalWorkOrder/launch/rentSubmit?id=${data.apartmentId}`,
        url: `/api/gzf/baseinfo/apartment/approval/submit`,
        data
    })
}
// 租金申请详情查询
export const getCzhiListApi = (id) => {
    return request({
        method: 'get',
        url: `/api/gzf/approvalWorkOrder/info/rent/${id}`,
    })
}
// 租金申请进行审批申请
export const apartmentAmountUpdatePUT = (data) => {
    return request({
        method: 'put',
        url: `/api/gzf/approvalWorkOrder/audit/rent`,
        data
    })
}
// 租金申请进行审批发起---优化，所有发起都是这个接口
export const getHousingCodeInfo = (url,params) =>
    request({
        method: 'put',
        url: `/api/gzf/approvalWorkOrder/launch/${url}?id=${params.id}`,
        params
    });
export const getHousingCodeInfoGET = (params) =>
    request({
        method: 'GET',
        url: `/api/gzf/approvalProcessingRecord/listPage`,
        params
    });
// 租金审批保存接口-1024-9-9
export const getHousingCodeInfoPOST = (data) =>
    request({
        method: 'POST',
        url: `/api/gzf/baseinfo/apartment/approval/save`,
        data
    });



